import React, { JSXElementConstructor, useEffect, useState } from 'react'
import { Dropdown, Grid, Header, Icon, Label, Menu, Message, Popup, Tab, Image, TabPane } from 'semantic-ui-react'
import { startCase, toLower } from 'lodash'
import { Link, useSearchParams } from 'react-router'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useGetTeamQuery } from '../../queries/GetTeamQuery'
import { useGetCustomerQuery } from '../../queries/GetCustomerQuery'
import DynamicBreadcrumbs from '../../components/Breadcrumbs/DynamicBreadcrumbs'
import { useTabLinkingService } from '../../services/TabLinkingService'
import { useRouterProps } from '../../router/RouterProps'
import TeamMetadataTable from './component/TeamMetadataTable'
import TeamLoginConnectionsTable from './component/TeamLoginConnectionsTable'
import TeamLoginOptionsTable from './component/TeamLoginOptionsTable'
import TeamFeatureFlags from './component/TeamFeatureFlags'
import TeamUsers from './component/TeamUsers'
import DeleteTeamModal from './component/DeleteTeamModal'
import ResetTeamModal from './component/ResetTeamModal'
import TeamNamesList from './component/TeamNamesList'
import { environmentColours } from './component/NewTeamsList'
import TeamDetailsTable from './component/TeamDetailsTable'
import { NewTeamNamesList } from './component/NewTeamNamesList'
import ConnectedFunctionList from './component/ConnectedFunctionList'
import MobileExtensionsList from './component/MobileExtensions/MobileExtensionsList'
import { PackagesList } from './component/PackagesList'

export interface Pane {
  menuItem: string
  render: () => JSX.Element
}

const panes: {
  [x: string]: JSXElementConstructor<{
    teamId: string
    customerId: string
    metadata: Record<string, any>
  }>
} = {
  Details: TeamDetailsTable,
  LoginConnections: TeamLoginConnectionsTable,
  LoginOptions: TeamLoginOptionsTable,
  FeatureFlags: TeamFeatureFlags,
  Metadata: TeamMetadataTable,
  Users: TeamUsers,
  TeamNames: TeamNamesList,
  ConnectedFunctions: ConnectedFunctionList,
  MobileExtensions: MobileExtensionsList,
  Packages: PackagesList
}

const makePane =
  (teamId: string, customerId: string, metadata: Record<string, any>) =>
  (
    name: string,
    Component: JSXElementConstructor<{
      teamId: string
      customerId: string
      metadata: Record<string, any>
    }>
  ) => {
    const startCaseName = startCase(name)
    const sentenceCaseName = startCaseName.charAt(0) + toLower(startCaseName.slice(1))
    return {
      menuItem: sentenceCaseName,
      render: () => (
        <TabPane>
          <Component teamId={teamId} customerId={customerId} metadata={metadata} />
        </TabPane>
      )
    }
  }

const getPanes = (teamId: string, customerId: string, metadata: Record<string, any>) =>
  Object.keys(panes).map(paneName => makePane(teamId, customerId, metadata)(paneName, panes[paneName]))

const isBannerVisible = (urlSearchParams: URLSearchParams): boolean => urlSearchParams.get('banner') === 'true'

const filterPane = (pane: Pane, isNelxTeam: boolean) =>
  (pane.menuItem !== 'Login options' && pane.menuItem !== 'Login connections') ||
  (pane.menuItem === 'Login options' && isNelxTeam) ||
  (pane.menuItem === 'Login connections' && !isNelxTeam)

export const NewTeamDetails = () => {
  const routerProps = useRouterProps()
  const [urlSearchParams] = useSearchParams()
  const teamId = routerProps.params.id!

  const [redirect, setRedirect] = useState(false)
  const [banner, setBanner] = React.useState(isBannerVisible(urlSearchParams))
  const { data: team } = useGetTeamQuery(teamId)
  const { data: customer } = useGetCustomerQuery(team?.customerId ?? '')
  const { setDocumentTitle } = useDocumentTitle(team?.primaryName)

  const isNelxTeam = team?.authProvider === 'auth0-organization'
  const filteredPanes = getPanes(teamId, customer?.id as string, team?.metadata ?? {}).filter(pane =>
    filterPane(pane, isNelxTeam)
  )

  const filteredtabs = isNelxTeam
    ? Object.keys(panes).filter(tab => tab !== 'LoginConnections')
    : Object.keys(panes).filter(tab => tab !== 'LoginOptions')

  useEffect(() => {
    if (team?.primaryName) {
      setDocumentTitle(team.primaryName)
    }
  }, [team])

  const { onTabChange, activeIndex } = useTabLinkingService(filteredtabs, 'details')

  useEffect(() => {
    if (redirect) {
      routerProps.navigate('/teams')
    }
  }, [redirect])

  return (
    <div className="route-component">
      {banner && (
        <Message
          data-testid="team-redirect-banner"
          header="Changes to the team model"
          content={
            <div>
              <p>
                This team has been migrated to the new model. You are currently viewing the new team details page.{' '}
                <a
                  href="https://skedulo.atlassian.net/wiki/spaces/ProductDevelopment/pages/2202730518/New+Team+Model+Overview"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b style={{ fontWeight: 800, textDecoration: 'underline' }}>Learn more</b>
                </a>{' '}
                about changes or contact <b style={{ fontWeight: 800 }}> #squad-core-services</b> via slack if you have
                any questions.
              </p>
            </div>
          }
          icon="warning"
          warning
          onDismiss={() => setBanner(false)}
        />
      )}

      <div style={{ marginBottom: '2.5rem' }}>
        <DynamicBreadcrumbs initialBreadcrumbs={[{ path: '/customers', name: 'Customers' }]} teamId={teamId} />
      </div>

      <Grid stackable>
        <Grid.Column floated="left" width={5} textAlign="left">
          <Header as="h2" color="blue">
            <Link to={`/customers/${customer?.id}`}>{customer?.name}</Link>
          </Header>
        </Grid.Column>
      </Grid>

      <Grid stackable style={{ height: 'calc(100% - 50px - 3rem)' }}>
        <NewTeamNamesList customer={customer} teamId={teamId} />
        <Grid.Column width={12} style={{ height: '100%' }}>
          <Grid>
            <Grid.Column floated="left" width={10} textAlign="left">
              <Header as="h2" style={{ display: 'flex', alignItems: 'flex-end' }}>
                {team?.primaryName}
                {team?.isSalesforce && (
                  <Popup
                    content="Salesforce Team"
                    position="top center"
                    trigger={
                      <Image
                        inline={true}
                        id="salesforce-logo"
                        src={require('../../images/salesforce-logo-icon.png')} // Workaround for import.meta.url not playing nice with jest.
                        size="small"
                        spaced="left"
                      />
                    }
                    style={{
                      opacity: 0.9,
                      textAlign: 'center'
                    }}
                    inverted
                  />
                )}
                {team?.environment && (
                  <Label color={environmentColours[team?.environment] ?? 'grey'} basic>
                    {team?.environment}
                  </Label>
                )}
                {team?.environment === 'Production' && (
                  <Popup
                    content="Production teams cannot be reset or deleted."
                    trigger={
                      <div>
                        <Icon name="info circle" size="small" style={{ paddingLeft: '5px' }} />
                      </div>
                    }
                    basic
                  />
                )}
              </Header>
            </Grid.Column>
            <Grid.Column floated="right" width={5} textAlign="right">
              <Menu secondary stackable>
                <Menu.Menu position="right">
                  {team?.environment !== 'Production' && (
                    <Dropdown id="action-menu" text="Actions" button basic className="action-menu">
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <ResetTeamModal
                            teamId={teamId}
                            redirectFn={() => setRedirect(true)}
                            menuItem
                            isSalesforceForm={team?.isSalesforce ?? false}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <DeleteTeamModal teamId={teamId} redirectFn={() => setRedirect(true)} menuItem />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Menu.Menu>
              </Menu>
            </Grid.Column>
          </Grid>
          <div style={{ marginBottom: '10px' }}>{team?.description}</div>
          <Tab
            data-testid="new-teams-details-tabs"
            panes={filteredPanes}
            onTabChange={onTabChange}
            activeIndex={activeIndex}
          />
        </Grid.Column>
      </Grid>
    </div>
  )
}
