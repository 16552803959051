import { Breadcrumb, Grid, Icon, Menu } from 'semantic-ui-react'
import { Navigate } from 'react-router'
import { useRouterProps } from '../../router/RouterProps'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useDeploymentsQueryByEnvironmentAndInstance } from '../../queries/deployments/GetDeploymentsQuery'
import { useFunctionQuery } from '../../queries/applications/GetFunctionQuery'
import { useReleaseApplicationQuery } from '../../queries/releases/GetReleaseApplicationsQuery'
import { ApplicationDeployments } from './components/ApplicationDeployments'
import { ApplicationInformation } from './components/ApplicationInformation'

export const FunctionDetails = () => {
  const routerProps = useRouterProps()
  const { documentTitle, setDocumentTitle } = useDocumentTitle('')

  const appName = routerProps.params.name!
  const environment = routerProps.params.environment!
  const instance = routerProps.params.instance!

  const { data: application, error } = useFunctionQuery(appName, instance, environment)
  const { data: releaseApplication } = useReleaseApplicationQuery(application?.name)

  if (application !== undefined && documentTitle !== application.name) {
    setDocumentTitle(application.name)
  }

  const { data: deployments, status: deploymentsStatus } = useDeploymentsQueryByEnvironmentAndInstance(
    environment,
    instance
  )

  const filteredDeployments = deployments
    ? deployments.filter(deployment => application?.name && deployment.application?.includes(application.name))
    : []

  return (
    <div className="route-component">
      {error && <Navigate to="/applications/applications" />}
      <span>
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => routerProps.navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={3} style={{ height: '100%' }}>
            <ApplicationInformation
              application={application}
              releaseApplication={releaseApplication}
              name={appName}
              instance={instance}
              environment={environment}
            />
          </Grid.Column>

          <Grid.Column width={11} stretched>
            <ApplicationDeployments deployments={filteredDeployments} loading={deploymentsStatus === 'loading'} />
          </Grid.Column>
        </Grid>
      </span>
    </div>
  )
}
