import { Button, Icon, Popup, Table } from 'semantic-ui-react'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router'
import { IApplication } from '../../../actions/Applications'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { FilterCategories, filtersMatchAny, searchParamsToFilters } from '../../filterComponents/Filters'
import { RestartApplicationModal } from './RestartApplicationModal'

interface IProps {
  applications?: IApplication[]
  filterCategories: FilterCategories
  handleRedeploy(application: IApplication): () => void
  loading: boolean
  simple?: boolean
}

const ApplicationStatus = (props: { unavailable: number }) =>
  props.unavailable > 0 ? (
    <Icon name="sync" color="yellow" size="large" />
  ) : (
    <Icon name="check circle" color="green" size="large" />
  )

const LambdaIcon = () => <Icon className="lambda circle large orange"></Icon>

export const ApplicationsList = (props: IProps) => {
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending')
  const { filterCategories, applications, simple } = props
  const [searchParams] = useSearchParams()

  const handleSort = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('ascending')
    } else {
      setSortDirection(prevState => (prevState === 'ascending' ? 'descending' : 'ascending'))
    }
  }

  const filterApplications = (): IApplication[] => {
    if (!applications || applications.length === 0) {
      return []
    }
    const filters = searchParamsToFilters(searchParams, filterCategories)
    let result = applications
    const groups = Object.groupBy(filters, filter => filter.category)
    for (const [category, categoryFilters] of Object.entries(groups)) {
      switch (category) {
        case 'tag': {
          result = result.filter(application => filtersMatchAny(categoryFilters!, application.meta.tag, false))
          break
        }
        default: {
          result = result.filter(application =>
            filtersMatchAny(categoryFilters!, application[category as keyof IApplication] as string, false)
          )
        }
      }
    }
    return result
  }

  const SortedApplicationHeaderCell = SortedHeaderCell(sortColumn, sortDirection, handleSort)

  const filteredApplications: IApplication[] = filterApplications()
  const sortedApplications = sortData(filteredApplications, sortColumn, sortDirection)
  return (
    <div className={props.simple ? '' : 'scrollable'}>
      <Table
        striped
        color="blue"
        stackable
        style={{ width: '100%' }}
        sortable
        className={!props.simple ? 'sticky-table' : undefined}
        aria-label="Applications"
      >
        <Table.Header>
          <Table.Row>
            {!simple && <SortedApplicationHeaderCell title="Application" name="name" />}
            <SortedApplicationHeaderCell title="Environment" />
            <SortedApplicationHeaderCell title="Instance" />
            <SortedApplicationHeaderCell title="Tag" />
            {!props.simple && <Table.HeaderCell>Available</Table.HeaderCell>}
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" style={{ width: '110px' }}>
              <Popup
                trigger={
                  <div>
                    {' '}
                    Restart <Icon name="question circle" />{' '}
                  </div>
                }
                content="Quickly Restarts the application without applying any changes."
                basic
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" style={{ width: '110px' }}>
              <Popup
                trigger={
                  <div>
                    {' '}
                    Redeploy <Icon name="question circle" />{' '}
                  </div>
                }
                content="Opens deployment form with current deployment pre-filled. Redeploying same version may change config."
                basic
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap loading={props.loading} array={filteredApplications} emptyMessage="No Applications">
          <Table.Body>
            {sortedApplications.map(row => {
              const baseRoute = row.kind === 'Deployment' ? '/applications/applications' : '/applications/function'
              return (
                <Table.Row key={row.meta.name + row.environment + row.instance}>
                  {!props.simple && <Table.Cell>{row.name || row.meta.name}</Table.Cell>}
                  <Table.Cell>{row.environment}</Table.Cell>
                  <Table.Cell>{row.instance}</Table.Cell>
                  <Table.Cell className="table-cell-overflow">{row.meta.tag}</Table.Cell>
                  {!props.simple && (
                    <Table.Cell collapsing textAlign="center">
                      {row.kind === 'Deployment' ? (
                        <>
                          {row.meta.available}/{row.meta.desired}
                        </>
                      ) : (
                        <LambdaIcon></LambdaIcon>
                      )}
                    </Table.Cell>
                  )}
                  <Table.Cell collapsing textAlign="center">
                    <ApplicationStatus unavailable={row.meta.unavailable} />
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    {row.kind === 'Deployment' && <RestartApplicationModal application={row} />}
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Button circular icon="redo" onClick={props.handleRedeploy(row)} className="circle-icon-button" />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Link to={`${baseRoute}/${row.environment}/${row.instance}/${row.meta.name}`}>
                      <Button icon="file alternate outline" className="square-icon-button" />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    </div>
  )
}
