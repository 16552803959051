import { Grid, Icon } from 'semantic-ui-react'
import { useDocumentTitle } from 'usehooks-ts'
import { useQueryClient } from '@tanstack/react-query'
import { SimpleSearch, useSimpleSearch } from '../searchComponents/SimpleSearch'
import { useReleasesQuery } from '../../queries/releases/GetReleaseQuery'
import { useReleaseApplicationsQuery } from '../../queries/releases/GetReleaseApplicationsQuery'
import { CutReleaseModal } from './Components/CutReleaseModal'
import { ReleasesList } from './Components/ReleasesList'

const title = 'Releases'

export const Releases = () => {
  const { data: applications } = useReleaseApplicationsQuery()
  const { data: releases } = useReleasesQuery()
  const queryClient = useQueryClient()
  useDocumentTitle(title)

  const createDetailsLink = (applicationName: string, version: string) =>
    `/applications/releases/${applicationName}/${version}`

  const searchOptions = [
    { text: 'All', value: 'all' },
    { text: 'Version', value: 'version' },
    { text: 'Application', value: 'applicationName' }
  ]
  const simpleSearch = useSimpleSearch(searchOptions)

  return (
    <div className="route-component">
      <Grid columns={2} verticalAlign="middle" stackable>
        <Grid.Column>
          <Grid verticalAlign="middle">
            <Grid.Column>
              <Icon
                size="large"
                name="refresh"
                onClick={queryClient.invalidateQueries(['releases'])}
                loading={releases === undefined || applications === undefined}
                className="clickable"
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleSearch simpleSearch={simpleSearch} />
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <CutReleaseModal applications={applications || []} />
        </Grid.Column>
      </Grid>
      <ReleasesList
        releases={releases}
        loading={releases === undefined || applications === undefined}
        searchValue={simpleSearch}
        createDetailsLink={createDetailsLink}
      />
    </div>
  )
}
