import { useEffect, useMemo } from 'react'
import { Breadcrumb, Divider, Grid, Icon, Menu } from 'semantic-ui-react'

import { useNavigate, useParams } from 'react-router'
import { useDeliveriesForApplicationAndVersionQuery } from '../../queries/deliveries/GetDeliveriesQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

import { TDeliveryDetails } from '../../actions/Deliveries'

import { IDeployment } from '../../actions/Deployments'
import { IDeploymentTemplateDetails } from '../../actions/Admin'
import { IDeliveryCheck } from '../../actions/DeliveryCheck'
import { useDeliveryChecksQuery } from '../../queries/deliveries/GetDeliveryChecksQuery'
import { useDeploymentsQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { useDeploymentTemplateQuery } from '../../queries/deployments/GetDeploymentTemplateQuery'
import { useRunDeliveryChecksMutation } from '../../mutations/deliveries/RunDeliveryChecks'
import { useGlobalApplicationPodsQuery } from '../../queries/applications/GetApplicationsQuery'
import { useDeliveryCommitDiffQuery } from '../../queries/deliveries/GetDeliveryCommitDiffQuery'
import { compareCommits, ICommitInformation } from '../../actions/Commits'
import { DeployDeliveryModal } from './Components/DeployDeliveryModal'
import { DeliveryInformation } from './Components/DeliveryInformation'
import { RejectDeliveryModal } from './Components/RejectDeliveryModal'

import { DeliveryCompare } from './Components/DeliveryCompare'
import { DeliveriesDeploymentInfo, ISimpleInstace } from './Components/DeliveriesDeploymentInfo'
import { DeliveriesChecksInfo } from './Components/DeliveriesChecksInfo'
import { DeliveriesPodsList } from './Components/DeliveriesPodsList'
import { DeliveryCommits } from './Components/DeliveryCommits'

const title = 'Delivery Details'

const isExpired = (expiredAt: string): boolean => !!expiredAt && new Date(expiredAt) <= new Date()

const categorizeChecksBasedOnStatus = (deliveryChecks?: IDeliveryCheck[]) => {
  const pending = deliveryChecks?.filter(check => check.status === 'created' && !isExpired(check.expiresAt)) || []
  const failed = deliveryChecks?.filter(check => check.status === 'failed' && !isExpired(check.expiresAt)) || []
  const warning = deliveryChecks?.filter(check => check.status === 'warning' && !isExpired(check.expiresAt)) || []
  const passed = deliveryChecks?.filter(check => check.status === 'passed' && !isExpired(check.expiresAt)) || []
  const expired = deliveryChecks?.filter(check => isExpired(check.expiresAt)) || []
  return {
    pending,
    failed,
    warning,
    passed,
    expired
  }
}

export const processDeployments = (
  testDeployments?: IDeployment[],
  testDeploymentTemplate?: IDeploymentTemplateDetails
) => {
  const missing = testDeploymentTemplate?.environments
    .filter(
      (
        env // check each env in test, staging has a deployment
      ) =>
        (env.name === 'test' || env.name === 'staging') &&
        !testDeployments?.some(dep => dep.env === env.name && dep.instance === env.instance)
    )
    .map(env => ({ environment: env.name, instance: env.instance }))

  const checkedEnvs: ISimpleInstace[] = []
  const failed: ISimpleInstace[] = []
  const succeeded: ISimpleInstace[] = []
  const pending: ISimpleInstace[] = []
  const running: ISimpleInstace[] = []
  testDeployments
    ?.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp)) // sort newest first
    .forEach(dep => {
      // check each deployment status
      const envName = { environment: dep.env, instance: dep.instance }
      if (!checkedEnvs.some(env => env.environment === envName.environment && env.instance === envName.instance)) {
        // not yet checked
        checkedEnvs.push(envName)
        switch (dep.status) {
          case 'successful':
            succeeded.push(envName)
            break
          case 'running':
            running.push(envName)
            break
          case 'pending':
            pending.push(envName)
            break
          default:
            failed.push(envName)
        }
      }
    })
  return { failed, succeeded, pending, running, missing: missing || [] }
}

export const DeliveryDetails = () => {
  // commits

  const params = useParams()
  const navigate = useNavigate()

  useDocumentTitle(title)

  const applicationName = params.applicationName!
  const version = params.version!

  const { data: delivery, status: deliveryStatus } = useDeliveriesForApplicationAndVersionQuery(
    applicationName,
    version
  )

  const { status: deploymentsStatus, data: deployments } = useDeploymentsQuery()

  const { status: deliveryChecksStatus, data: deliveryChecks } = useDeliveryChecksQuery(
    applicationName,
    version,
    !!delivery
  )

  // Could be removed if we always have the deployment template in the delivery
  const { data: deploymentTemplateWithRepoName } = useDeploymentTemplateQuery(
    delivery?.application.repositoryName,
    !delivery?.application.deploymentTemplate
  )

  const deploymentTemplate = delivery?.application.deploymentTemplate ?? deploymentTemplateWithRepoName

  const { data: deliveryCommits } = useDeliveryCommitDiffQuery(applicationName, version, delivery?.status === 'pending')

  const { data: pods, status: podsStatus } = useGlobalApplicationPodsQuery(delivery?.application.repositoryName)

  const runDeliveryChecks = useRunDeliveryChecksMutation()

  const {
    pending: pendingChecks,
    failed: failedChecks,
    warning: warningChecks,
    passed: passedChecks,
    expired: expiredChecks
  } = categorizeChecksBasedOnStatus(deliveryChecks)

  const deploymentCheck = () =>
    deploymentsStatus !== 'loading' &&
    deliveryChecksStatus !== 'loading' &&
    failedDeployments.length + missingDeployments.length + runningDeployments.length + pendingDeployments.length ===
      0 &&
    passedChecks.length === deliveryChecks?.length

  const deploymentGateMessage = () => {
    const message = []
    if (deploymentsStatus === 'loading') {
      message.push('Checking deployments...')
    }
    if (failedDeployments.length) {
      message.push('This build has failed deployments to some environments.')
    }
    if (missingDeployments.length) {
      message.push(
        // prettier-ignore
        'This build has not been deployed to some staging and test environments included in the application\'s deployment template.'
      )
    }
    if (pendingDeployments.length) {
      message.push('This build has outstanding pending deployments to some environments.')
    }
    if (runningDeployments.length) {
      message.push('This build has outstanding running deployments to some environments.')
    }

    failedChecks.forEach(check => {
      const failedMessage = `'${check.name}' failed. Please re-run (if applicable) or ensure you have checked the details before proceeding.`
      message.push(failedMessage)
    })
    expiredChecks.forEach(check => {
      const expiredMessage = `'${check.name}' expired at ${check.expiresAt}. Please re-run this check before proceeding.`
      message.push(expiredMessage)
    })
    warningChecks.forEach(check => {
      const warningMessage = `'${check.name}' is in warning status. Please ensure you have checked the details before proceeding.`
      message.push(warningMessage)
    })

    return message
  }

  const filteredDeployments = useMemo(
    () => deployments?.filter(dep => dep.application === deploymentTemplate?.name && dep.tag === delivery?.tag) || [],
    [deployments, deploymentTemplate, delivery?.tag]
  )

  const {
    failed: failedDeployments,
    succeeded: successfulDeployments,
    pending: pendingDeployments,
    running: runningDeployments,
    missing: missingDeployments
  } = useMemo(
    () => processDeployments(filteredDeployments, deploymentTemplate),
    [filteredDeployments, deploymentTemplate]
  )

  const environmentApplicationVersions =
    deliveryCommits && Object.fromEntries(deliveryCommits.environments.map(env => [env.environment, env.version]))

  const getListOfApplicationCommits = (baseVersion: string, headVersion: string): Promise<ICommitInformation[]> =>
    delivery
      ? compareCommits(delivery.application.repositoryName, baseVersion, headVersion).then(resp => resp.commits_ahead)
      : Promise.resolve([])

  const environmentConfigVersions =
    delivery?.status === 'pending' && pods
      ? Object.fromEntries(pods.map(pod => [`${pod.instance}-${pod.namespace}`, `${pod.configVersion}`]))
      : {}

  const getListOfConfigCommits = async (baseVersion: string, headVersion: string): Promise<ICommitInformation[]> => {
    if (baseVersion === headVersion) {
      return Promise.resolve([])
    }
    return compareCommits('ansible-skedulo-kube', baseVersion, headVersion).then(resp => resp.commits_ahead)
  }

  const handleRerunDeliveryChecks = async () => {
    runDeliveryChecks.mutate({ application: applicationName, version })
  }

  useEffect(() => {
    if (deliveryStatus === 'error' && !delivery) {
      navigate('/applications/deliveries')
    }
  }, [deliveryStatus, delivery, navigate])

  return (
    <div className="route-component">
      <span>
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <Grid columns={2}>
                <Grid.Column width={8}>
                  <DeployDeliveryModal
                    delivery={delivery}
                    disabled={delivery?.status !== 'pending'}
                    check={!deploymentCheck()}
                    checkMessage={[...deploymentGateMessage()]}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <RejectDeliveryModal delivery={delivery} disabled={delivery?.status !== 'pending'} />
                </Grid.Column>
              </Grid>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={3} style={{ height: '100%' }}>
            <DeliveryInformation delivery={delivery || ({} as TDeliveryDetails)} />
          </Grid.Column>
          <Grid.Column width={13} style={{ height: '100%' }} className="scrollable-no-margin">
            <DeliveriesPodsList
              pods={pods || []}
              loading={podsStatus === 'loading' || deliveryStatus === 'loading'}
              tag={delivery?.tag}
            />
            <Divider />
            <DeliveriesChecksInfo
              deliveryStatus={delivery?.status}
              deliveryChecks={deliveryChecks || []}
              pending={pendingChecks.length}
              warning={warningChecks.length}
              failed={failedChecks.length}
              expired={expiredChecks.length}
              passed={passedChecks.length}
              loading={deliveryChecksStatus === 'loading'}
              handleRerunCheck={handleRerunDeliveryChecks}
              isExpired={isExpired}
            />
            <Divider />
            <DeliveriesDeploymentInfo
              deployments={filteredDeployments}
              loading={deploymentsStatus === 'loading'}
              failed={failedDeployments}
              missing={missingDeployments}
              pending={pendingDeployments}
              running={runningDeployments}
              successful={successfulDeployments}
            />
            <Divider />
            <DeliveryCommits
              title="Application"
              environmentVersions={environmentApplicationVersions}
              owner={delivery?.application.repositoryOwner}
              repository={delivery?.application.repositoryName}
              version={delivery?.tag}
              status={delivery?.status}
              getListOfCommits={getListOfApplicationCommits}
            />
            <Divider />
            <DeliveryCommits
              title="Configuration"
              environmentVersions={environmentConfigVersions}
              owner="skedulo"
              repository="ansible-skedulo-kube"
              version={delivery?.configVersion}
              status={delivery?.status}
              getListOfCommits={getListOfConfigCommits}
            />

            <DeliveryCompare thisDelivery={delivery} />
            <Divider />
          </Grid.Column>
        </Grid>
      </span>
    </div>
  )
}
