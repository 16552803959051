import { Button, Grid, Icon } from 'semantic-ui-react'
import { Link } from 'react-router'
import { startCase } from 'lodash'
import { useRouterProps } from '../router/RouterProps'
import { getColorScheme } from './header/Header'

export const siteOptions = [
  { path: '', text: 'Admin console home', desc: 'Customer and team management' },
  { path: 'applications', text: 'Application management', desc: 'Applications, releases, deployments, etc' },
  { path: 'old-model', text: 'Legacy admin console', desc: 'The old customer model' }
]

const Landing = () => {
  const props = useRouterProps()
  const path = props.location.pathname.split('/')[1]
  const currentPath = path !== 'old-model' && path !== 'applications' ? '' : path

  const currentSite = siteOptions.find(opt => currentPath === opt.path) ?? siteOptions[0]

  const navButtons = siteOptions
    .filter(opt => currentPath !== opt.path)
    .map(opt => (
      <Grid.Column key={opt.path} width={5}>
        <Button
          as={Link}
          to={'/' + opt.path}
          style={getColorScheme(opt.path, 'includeBorder')}
          fluid
          id="landing-nav-button"
        >
          <span>
            <h3>{opt.text}</h3>
            &nbsp;<Icon name="arrow right"></Icon>
          </span>
          {opt.desc}
        </Button>
      </Grid.Column>
    ))

  return (
    <div className="route-component legacy-page">
      <Grid centered columns={1} id="landing-page">
        <Grid.Row>
          <Grid.Column verticalAlign="middle" width={10} id="landing-welcome">
            <span></span>
            <div>
              <h1>Welcome to {startCase(currentSite.text)}</h1>
              <p>{currentSite.desc}</p>
            </div>
            <h4>Looking for something else?</h4>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>{navButtons}</Grid.Row>
      </Grid>
    </div>
  )
}

export default Landing
