import * as React from 'react'

import { Link } from 'react-router'
import { Button, DropdownProps, Header, Input, InputOnChangeData, Menu, Segment } from 'semantic-ui-react'
import { DEFAULT_REGION, IRegions } from '../../../actions/Regions'
import { editTeamDetails, IEditedTeam, ITeam } from '../../../actions/Teams'
import { createErrorToast } from '../../alertComponents/Alert'
import { CustomerSelector } from '../../formComponents/Selectors'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'

interface IProps {
  team: ITeam
  loadTeam(): void
  regions: IRegions
  loading: boolean
}

interface IState {
  editable: boolean
  editing: boolean
  editedCustomer: string
  editedDescription: string
  editedName: string
}

export class TeamInformation extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      editable: false,
      editing: false,
      editedCustomer: ' ',
      editedName: '',
      editedDescription: ''
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.team !== prevProps.team) {
      const { name, description, customerId } = this.props.team
      this.setState({ editedName: name, editedDescription: description })
      if (customerId) {
        this.setState({ editedCustomer: customerId })
      } else {
        this.setState({ editedCustomer: ' ' })
      }
    }
  }

  toggleEditable = (): void => {
    this.setState(prevState => ({ editable: !prevState.editable }))
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps): void => {
    const name = data.name
    const value = data.value
    // The ...this.state is required due to a @types/react bug should be removed when this is fixed.
    this.setState({ ...this.state, [name]: value })
  }

  onChangeLowerCase = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData): void => {
    this.onChange(event, {
      ...data,
      value: data.value.toLowerCase()
    })
  }

  getEditedCustomerId = (editedCustomer: string, customerId?: string): string | null | undefined => {
    if (editedCustomer === ' ' && customerId !== undefined) {
      return null
    }
    if (editedCustomer === customerId || (editedCustomer === ' ' && customerId === undefined)) {
      return undefined
    }
    return editedCustomer
  }

  submitEditedTeam = (): void => {
    const { team, loadTeam } = this.props
    this.setState({ editing: true })
    const { editedDescription, editedName, editedCustomer } = this.state
    const { name, description, customerId, id } = team
    const editedTeam: IEditedTeam = {
      name: name === editedName ? undefined : editedName,
      description: description === editedDescription ? undefined : editedDescription,
      customerId: this.getEditedCustomerId(editedCustomer, customerId)
    }
    editTeamDetails(id, editedTeam).then(
      () => {
        this.setState({ editing: false, editable: false })
        loadTeam()
      },
      error => {
        createErrorToast(error)
        this.setState({ editing: false })
      }
    )
  }

  render() {
    const { editable, editedName, editedCustomer, editedDescription } = this.state
    const { regions } = this.props
    const { id, name, description, customerName, customerId, region, createdBy, updatedBy, createdDate, updatedDate } =
      this.props.team

    return (
      <div>
        <Header as="h3">Team Info</Header>
        <Segment color="blue">
          <LoaderWrap loading={this.props.loading}>
            <React.Fragment>
              <Header size="tiny">Name:</Header>
              {editable ? (
                <Input
                  fluid
                  focus
                  id="input-name"
                  name="editedName"
                  value={editedName}
                  onChange={this.onChangeLowerCase}
                />
              ) : (
                <p>{name}</p>
              )}
              <Header size="tiny">Team Description:</Header>
              {editable ? (
                <Input
                  fluid
                  focus
                  id={'input-description'}
                  name="editedDescription"
                  value={editedDescription}
                  onChange={this.onChange}
                />
              ) : (
                <p>{description}</p>
              )}
              <Header size="tiny">Customer:</Header>
              {editable ? (
                <CustomerSelector
                  name="editedCustomer"
                  value={editedCustomer}
                  onChange={this.onChange}
                  id="edit-customer-selector"
                />
              ) : customerId ? (
                <Link to={'/old-model/customers/' + customerId}>
                  <p> {customerName} </p>
                </Link>
              ) : (
                <p>No Customer</p>
              )}
              <Header size="tiny">Team ID:</Header>
              <p>{id}</p>
              <Header size="tiny">Region:</Header>
              <p>{regions ? regions[region || DEFAULT_REGION] || region || DEFAULT_REGION : null}</p>
              <Header size="tiny">Created By:</Header>
              <p>{createdBy || 'Unknown'}</p>
              <Header size="tiny">Updated By:</Header>
              <p>{updatedBy || 'Unknown'}</p>
              <Header size="tiny">Created Date:</Header>
              <p>{createdDate}</p>
              <Header size="tiny">Updated Date:</Header>
              <p>{updatedDate}</p>
              <Menu text stackable>
                <Menu.Item position="left">
                  <Button onClick={this.toggleEditable} content={editable ? 'Cancel' : 'Edit'} />
                </Menu.Item>
                {editable && (
                  <Menu.Item position="right">
                    <Button primary onClick={this.submitEditedTeam} content="Save" />
                  </Menu.Item>
                )}
              </Menu>
            </React.Fragment>
          </LoaderWrap>
        </Segment>
      </div>
    )
  }
}
