import * as React from 'react'
import { Navigate } from 'react-router'
import { Breadcrumb, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getCustomerByName } from '../../actions/NewCustomers'
import { getCustomers, ICustomer, updateCustomer } from '../../actions/Customers'
import { getRegions, IRegions, toRegions } from '../../actions/Regions'
import { getTeams, ITeam } from '../../actions/Teams'
import { IMetadata } from '../../actions/Tenants'
import { createErrorToast } from '../alertComponents/Alert'
import { DynamicMetadataTable } from '../tableComponents/DynamicMetadataTable'
import { TeamsList } from '../teams/components/TeamsList'
import { MigrateCustomerModal } from '../newCustomers/component/MigrateCustomerModal'
import { filterData } from '../tableComponents/TableFunctions'
import MigrationWarning from '../banners/MigrationWarning'
import { RouterProps } from '../../router/RouterProps'
import { CustomerInformation } from './components/CustomerInformation'
import { DeleteCustomerModal } from './components/DeleteCustomerModal'

interface IState {
  loadingCustomers: boolean
  loadingNewModelCustomer: boolean
  loadingTeams: boolean
  loadingError?: string
  redirectUrl: string
  customer?: ICustomer
  teams: ITeam[]
  regions: IRegions
}

type IProps = RouterProps

export default class CustomerDetails extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      loadingCustomers: false,
      loadingNewModelCustomer: false,
      loadingTeams: false,
      teams: [],
      redirectUrl: '',
      regions: {}
    }
  }

  async componentDidMount() {
    this.setState({ loadingTeams: true })
    await Promise.all([this.fetchCustomer(), this.fetchTeams(), this.fetchRegions()])
    this.fetchNewModelCustomer()
    this.setState({ loadingTeams: false })
  }

  componentDidUpdate() {
    document.title = formatDocumentTitle(this.state.customer?.name)
  }

  setRedirect = (redirectUrl: string) => () => {
    this.setState({ redirectUrl })
  }

  fetchCustomer = async (): Promise<void> => {
    const id = this.props.params.id || null
    this.setState({ loadingCustomers: true })
    try {
      const customers = await getCustomers()
      const customer = customers.find(tempCustomer => tempCustomer.id === id)
      if (customer) {
        this.setState({
          customer
        })
      } else {
        createErrorToast(`No customer found with the id: ${id}`)
        this.setRedirect('/old-model/customers')()
      }
    } catch (error) {
      createErrorToast(error)
    } finally {
      this.setState({ loadingCustomers: false })
    }
  }

  fetchTeams = async (): Promise<void> => {
    try {
      const teams = await getTeams()
      this.setState({
        teams
      })
    } catch (error) {
      createErrorToast(error)
    }
  }

  fetchRegions = async (): Promise<void> => {
    try {
      const regionInfos = await getRegions()
      this.setState({
        regions: toRegions(regionInfos)
      })
    } catch (error) {
      createErrorToast(error)
    }
  }

  fetchNewModelCustomer = async (): Promise<void> => {
    this.setState({ loadingNewModelCustomer: true })

    try {
      const customerName = this.state.customer?.name || ''
      const newModelCustomer = await getCustomerByName(customerName)

      if (newModelCustomer) {
        this.setState({ loadingNewModelCustomer: false })
        this.setRedirect(`/customers/${newModelCustomer.id}?banner=true`)()
      }
    } catch (error: any) {
      // Don't throw an error toast when we receive status 404 after fetching customer in new model.
      if (error.response.status !== 404) {
        createErrorToast(error)
      }
      this.setState({ loadingNewModelCustomer: false })
    }
  }

  updateMetadata = (metadata: IMetadata) => updateCustomer(this.state.customer?.id || '', { metadata })

  render() {
    const { teams, loadingTeams, loadingCustomers, customer, redirectUrl, regions, loadingNewModelCustomer } =
      this.state

    const customerTeams = filterData({ searchValue: customer?.id || '', searchCategory: 'customerId' }, teams)

    return (
      <div className="route-component">
        {redirectUrl && <Navigate to={redirectUrl} />}

        <MigrationWarning isCustomer />

        <Grid columns={2} verticalAlign="middle" stackable>
          <Grid.Column>
            <Grid verticalAlign="middle">
              <Grid.Column>
                <Breadcrumb>
                  <Breadcrumb.Section className="back-button" onClick={() => this.props.navigate(-1)}>
                    <Icon name="chevron left" size="big" /> Back
                  </Breadcrumb.Section>
                </Breadcrumb>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="right">
            {!loadingTeams && customer && (
              <MigrateCustomerModal customer={customer} teams={customerTeams} setRedirect={this.setRedirect} />
            )}
            <DeleteCustomerModal
              customerName={customer?.name}
              customerId={customer?.id}
              setRedirect={this.setRedirect('/old-model/customers')}
            />
          </Grid.Column>
        </Grid>

        <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={5} style={{ maxHeight: '100%' }}>
            <CustomerInformation
              customer={customer}
              fetchCustomer={this.fetchCustomer}
              loading={loadingCustomers && loadingNewModelCustomer}
            />
          </Grid.Column>
          <Grid.Column width={11} style={{ maxHeight: '100%' }}>
            <Header as="h3">Teams:</Header>
            <Segment color="blue">
              <TeamsList
                teams={teams}
                loading={loadingTeams}
                regions={regions}
                searchValue={{ searchValue: customer?.id || '', searchCategory: 'customerId' }}
                simple
              />
            </Segment>
            <Divider />
            <DynamicMetadataTable
              loading={loadingCustomers}
              updateMetadata={this.updateMetadata}
              updateData={this.fetchCustomer}
              metadata={customer?.metadata}
            />
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
