import { Grid, Header, Label, Segment } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router'
import { difference, flatten, groupBy, keys, reverse, sortBy, values } from 'lodash'
import { FilterSearch } from '../../../views/filterComponents/FilterSearch'
import { environmentOptions } from '../../../actions/Tenants'
import { ownerOptions } from '../../../views/newTeams/services/TeamInformationService'
import { RegionFlagPopup } from '../../../utils/regionFlags'
import { SortableHeaderCell } from '../../../components/TableComponents/BaseTableComponents/SortableHeaderCell'
import { sortData } from '../../../views/tableComponents/TableFunctions'
import { MemoizedColumns, Table, createMemoizedColumns } from '../../../components/TableComponents/ReactTable'
import { IPackageTeam, IPackageTenant } from '../../../actions/Package'
import { formatDate } from '../../../utils/dateUtils'
import { useGetPackageTenantsQuery } from '../../../queries/packages/GetPackageTenantsQuery'
import { environmentColours } from '../../../views/newTeams/component/NewTeamsList'
import {
  FilterCategoryOptions,
  Filters,
  filtersMatchAny,
  searchParamsToFilters
} from '../../../views/filterComponents/Filters'
import { PackageInstallationStatus } from './PackageInstallationStatus'

const TeamNameURL = ({ team }: { team?: { id: string; name: string } }) => (
  <Link to={`/teams/${team?.id}`} data-testid="package-teams-name-cell">
    {team?.name}
  </Link>
)

export interface IPackageTenantsList {
  packageName: string
  packageVersion: string
  title: string
  regionCodes?: string[]
}

export const PackageTenantsList = (props: IPackageTenantsList) => {
  const [searchParams] = useSearchParams()
  const { title, packageName, packageVersion, regionCodes } = props
  const [region, setRegion] = useState<string>('AU')

  const {
    isLoading: isLoadingTeams,
    fetchNextPage,
    hasNextPage,
    data,
    isFetchingNextPage,
    isFetching
  } = useGetPackageTenantsQuery(packageName, packageVersion, region, '')

  const [allTeams] = useState<Record<string, IPackageTenant[]>>({})
  if (data) {
    allTeams[region] = data?.pages.flatMap(p => p.result.map(r => ({ ...r, region }))) ?? []
  }

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage()
    } else if (difference(regionCodes, keys(allTeams))[0]) {
      setRegion(difference(regionCodes, keys(allTeams))[0])
    }
  }, [data, fetchNextPage, hasNextPage, regionCodes, allTeams])

  const [{ column: sortColumn, direction: sortDirection }, setSort] = useState<{
    column: string
    direction: 'ascending' | 'descending'
  }>({
    column: 'completedAt',
    direction: 'descending'
  })

  const handleSort = (column: string) => () => {
    const flipDirection = sortDirection === 'ascending' ? 'descending' : 'ascending'
    const direction = sortColumn === column ? flipDirection : 'ascending'
    setSort({ column, direction })
  }

  const SortablePackageHeaderCell = SortableHeaderCell(sortColumn, sortDirection, handleSort)

  const packageTenantColumns = createMemoizedColumns<IPackageTenant>(
    [
      {
        Header: <SortablePackageHeaderCell title="Name" name="name" />,
        accessor: (packageTenant: IPackageTenant) => packageTenant.team?.name,
        Cell: ({ row }: { row: Row<IPackageTenant> }) => <TeamNameURL team={row.original.team} />,
        id: 'name'
      },
      {
        Header: 'Region',
        accessor: (packageTenant: IPackageTenant) => packageTenant.region,
        Cell: ({ row }: { row: Row<IPackageTenant> }) => <RegionFlagPopup region={row.original.region} />
      },
      {
        Header: 'Tenant ID',
        accessor: (packageTenant: IPackageTenant) => packageTenant.tenantId
      },
      {
        Header: 'Status',
        accessor: (packageTenant: IPackageTenant) => packageTenant.status,
        Cell: ({ row }: { row: Row<IPackageTenant> }) => <PackageInstallationStatus status={row.original.status} />
      },
      {
        Header: <SortablePackageHeaderCell title="Installed" name="completedAt" />,
        accessor: (packageTenant: IPackageTenant) => packageTenant.completedAt,
        Cell: ({ row }: { row: Row<IPackageTenant> }) => (
          <>{formatDate(row.original.completedAt, 'MMM DD, YYYY - hh:mma')}</>
        ),
        id: 'completedAt'
      },
      {
        Header: 'Installed by',
        accessor: (packageTenant: IPackageTenant) => packageTenant.createdBy
      },
      {
        Header: 'Customer',
        accessor: (packageTenant: IPackageTenant) => packageTenant.team?.customerName,
        Cell: ({ row }: { row: Row<IPackageTenant> }) =>
          row.original.team ? (
            <Link to={`/customers/${row.original.team.customerId}`}>{row.original.team.customerName}</Link>
          ) : (
            <></>
          )
      },
      {
        Header: <SortablePackageHeaderCell title="Environment" name="environment" />,
        accessor: (packageTenant: IPackageTenant) => packageTenant.team?.environment,
        Cell: ({ row }: { row: Row<IPackageTenant> }) =>
          row.original.team ? (
            <Label color={environmentColours[row.original.team?.environment] ?? 'grey'} basic>
              {row.original.team?.environment}
            </Label>
          ) : (
            <></>
          ),
        id: 'environment'
      },
      {
        Header: <SortablePackageHeaderCell title="Owner" name="owner" />,
        accessor: (packageTenant: IPackageTenant) => packageTenant.team?.owner,
        id: 'owner'
      }
    ],
    [sortColumn, sortDirection]
  )

  const filterCategories = new Map<string, FilterCategoryOptions>([
    ['name', { text: 'Name' }],
    ['tenantId', { text: 'Tenant ID' }],
    ['customer', { text: 'Customer' }],
    ['environment', { text: 'Environment', values: environmentOptions }],
    ['owner', { text: 'Owner', values: ownerOptions.flatMap(o => o.value?.toString() ?? '') }],
    ['region', { text: 'Region', values: regionCodes }]
  ])

  const filterTeams = (): IPackageTenant[] => {
    if (flatten(values(allTeams)).length === 0) {
      return []
    }
    const filters = searchParamsToFilters(searchParams, filterCategories)
    let result = flatten(values(allTeams))
    const groups = groupBy(filters, filter => filter.category)
    for (const [category, categoryFilters] of Object.entries(groups)) {
      result = result.filter(p =>
        filtersMatchAny(
          categoryFilters,
          (p[category as keyof IPackageTenant] as string) ?? (p.team?.[category as keyof IPackageTeam] as string),
          false
        )
      )
    }
    return result
  }

  const sortTeams = () => {
    if (['name', 'environment', 'owner'].includes(sortColumn)) {
      if (sortDirection === 'ascending') {
        return sortBy(filterTeams(), p => p.team?.[sortColumn as keyof IPackageTeam])
      }
      return reverse(sortBy(filterTeams(), p => p.team?.[sortColumn as keyof IPackageTeam]))
    } else {
      return sortData(filterTeams(), sortColumn, sortDirection)
    }
  }

  const displayTeams = sortTeams() ?? []

  return (
    <Segment>
      <Header as="h3">{title}</Header>
      <Grid verticalAlign="middle" stackable>
        <Grid.Column width={3}>
          <FilterSearch category="name" inputProps={{ icon: 'search', iconPosition: 'left' }} />
        </Grid.Column>
        <Grid.Column width={13}>
          <Filters filterCategories={filterCategories} />
        </Grid.Column>
      </Grid>
      <p style={{ paddingTop: '10px', marginBottom: 0 }}>
        <small>
          {displayTeams.length} team{displayTeams.length !== 1 && 's'}
        </small>
      </p>
      <div style={{ overflowY: 'auto' }}>
        <Table
          columns={packageTenantColumns as MemoizedColumns<any>}
          data={displayTeams ?? []}
          emptyMessage="No tenants found"
          textAlign="left"
          loading={isLoadingTeams || isFetchingNextPage || isFetching}
          style={{ marginTop: 0 }}
        />
      </div>
    </Segment>
  )
}
