import dayjs from 'dayjs'

import * as React from 'react'

import { Divider, Header, Icon, List, Segment } from 'semantic-ui-react'
import { TDeliveryDetails } from 'src/actions/Deliveries'
import { Link } from 'react-router'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'
import { GithubURL } from '../../../components/GithubURL'
import { DeliveryStatusIcon, DeliveryStatusText } from './DeliveryStatusIcon'

interface IProps {
  delivery: TDeliveryDetails
}

export const DeliveryInformation = (props: IProps) => {
  const { delivery } = props
  return (
    <div>
      <Header as="h3">Delivery Info</Header>
      <Segment color="blue">
        <LoaderWrap loading={!delivery.applicationName}>
          <React.Fragment>
            <List name="info-list">
              <List.Item>
                <List.Header>Application</List.Header>
                {delivery.applicationName}
              </List.Item>
              <List.Item>
                <List.Header>Version</List.Header>
                {delivery.version}
              </List.Item>
            </List>
            <Divider horizontal> Details </Divider>
            <List name="info-list">
              <List.Item>
                <DeliveryStatusIcon status={delivery.status} size="medium" />
                <List.Content>
                  <List.Header>Status</List.Header>
                  <DeliveryStatusText status={delivery.status} />
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="git" />
                <List.Content>
                  <List.Header>Tag</List.Header>
                  <div className="table-cell-overflow">
                    {delivery.application ? (
                      <>
                        <GithubURL
                          owner={delivery.application.repositoryOwner}
                          repository={delivery.application.repositoryName}
                          commit={delivery.tag}
                        />{' '}
                        <Icon name="external" />
                      </>
                    ) : (
                      delivery.tag
                    )}
                  </div>
                </List.Content>
              </List.Item>
              {delivery.pinConfiguration && delivery.configVersion && (
                <List.Item>
                  <List.Icon name="pin" />
                  <List.Content>
                    <List.Header>Config Version</List.Header>
                    <div className="table-cell-overflow">
                      <GithubURL owner="Skedulo" repository="ansible-skedulo-kube" commit={delivery.configVersion} />{' '}
                      <Icon name="external" />
                    </div>
                  </List.Content>
                </List.Item>
              )}
              {delivery.createdBy ? (
                <List.Item>
                  <List.Icon name="user outline" />
                  <List.Content>
                    <List.Header>Created By</List.Header>
                    <div className="table-cell-overflow">{delivery.createdBy}</div>
                  </List.Content>
                </List.Item>
              ) : null}
              {delivery.createdAt && (
                <List.Item>
                  <List.Icon name="clock outline" />
                  <List.Content>
                    <List.Header>Created At</List.Header>
                    <div className="table-cell-overflow">
                      {dayjs(delivery.createdAt).format('MMMM D YYYY, h:mm:ss a')}
                    </div>
                  </List.Content>
                </List.Item>
              )}
              {delivery.completedBy ? (
                <List.Item>
                  <List.Icon name="user" />
                  <List.Content>
                    <List.Header>Completed By</List.Header>
                    <div className="table-cell-overflow">{delivery.completedBy}</div>
                  </List.Content>
                </List.Item>
              ) : null}
              {(delivery.status === 'approved' || delivery.status === 'rejected') && (
                <List.Item>
                  <List.Icon name="clock" />
                  <List.Content>
                    <List.Header>Completed At</List.Header>
                    <div className="table-cell-overflow">
                      {dayjs(delivery.updatedAt).format('MMMM D YYYY, h:mm:ss a')}
                    </div>
                  </List.Content>
                </List.Item>
              )}
              {delivery.completedExplanation ? (
                <List.Item>
                  <List.Icon name="sticky note" />
                  <List.Content>
                    <List.Header>Completed Explanation</List.Header>
                    <div className="table-cell-overflow">{delivery.completedExplanation}</div>
                  </List.Content>
                </List.Item>
              ) : null}
              {delivery.supersededBy ? (
                <List.Item>
                  <List.Icon name="redo" />
                  <List.Content>
                    <List.Header>Superseded by</List.Header>
                    <Link to={`/applications/deliveries/${delivery.applicationName}/${delivery.supersededBy}`}>
                      {delivery.supersededBy}
                    </Link>
                  </List.Content>
                </List.Item>
              ) : null}
            </List>
            {/*
            Deprecated pending implementation of logging this info in Admin-API
            Confusing for users to see no info here.
            <Divider horizontal> Tests </Divider>
            <List>
              <List.Item>
                <List.Icon name={getTestStatusIcon(delivery.testsPass)} />
                <List.Content>
                  <List.Header>Tests Passed</List.Header>
                  {_.startCase(delivery.testsPass?.toString())}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="clock" />
                <List.Content>
                  <List.Header>Passed At</List.Header>
                  {delivery.testsPassedAt || (
                    <Placeholder>
                      <Placeholder.Line length="very short" />
                    </Placeholder>
                  )}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="book" />
                <List.Content>
                  <List.Header>Logged By</List.Header>
                  {delivery.testsLoggedBy || (
                    <Placeholder>
                      <Placeholder.Line length="very short" />
                    </Placeholder>
                  )}
                </List.Content>
              </List.Item>
            </List>
            */}
          </React.Fragment>
        </LoaderWrap>
      </Segment>
    </div>
  )
}
