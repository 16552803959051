import { Link } from 'react-router'
import { Button, Grid, Icon } from 'semantic-ui-react'
import { useQueryClient } from '@tanstack/react-query'
import { IApplication } from '../../actions/Applications'
import { DeployApplicationModal } from '../deployments/components/DeployApplicationModal'
import { useApplicationsQuery } from '../../queries/applications/GetApplicationsQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useDeploymentModal } from '../../hooks/useDeploymentModal'
import { FilterCategoryOptions, Filters } from '../filterComponents/Filters'
import { FilterSearch } from '../filterComponents/FilterSearch'
import { ApplicationsList } from './components/ApplicationsList'

export const Applications = () => {
  const { data: applications, status: applicationsStatus } = useApplicationsQuery()
  useDocumentTitle('Applications')
  const queryClient = useQueryClient()

  const modal = useDeploymentModal()

  const filterCategories = new Map<string, FilterCategoryOptions>([
    ['name', { text: 'Application' }],
    ['environment', { text: 'Environment' }],
    ['instance', { text: 'Instance' }],
    ['tag', { text: 'Tag' }],
    ['config_hash', { text: 'Config' }]
  ])

  const handleRedeploy = (app: IApplication) => () => {
    modal.populateDeployment({
      application: app.name,
      env: app.environment,
      instance: app.instance,
      tag: app.meta.tag,
      config: '',
      check_mode: false
    })
    modal.setModalOpen(true)
  }

  if (applications) {
    filterCategories.set('name', { text: 'Application', values: applications.map(application => application.name) })
  }

  return (
    <div className="route-component">
      <Grid>
        <Grid.Column width={1} verticalAlign="middle">
          <Icon
            size="large"
            name="refresh"
            onClick={() => queryClient.invalidateQueries(['applications'])}
            loading={applicationsStatus === 'loading'}
            className="clickable"
            aria-label="Refresh Applications"
          />
        </Grid.Column>
        <Grid.Column width={2} textAlign="left" verticalAlign="middle">
          <FilterSearch category="name" />
        </Grid.Column>
        <Grid.Column width={11} textAlign="left" verticalAlign="middle">
          <Filters filterCategories={filterCategories} />
        </Grid.Column>
        <Grid.Column floated="right" width={2} verticalAlign="middle">
          <Button
            as={Link}
            to="/applications/applications/compare"
            content="Compare Environments"
            className="form-button-sked-blue"
            primary
          />
        </Grid.Column>
      </Grid>
      <ApplicationsList
        filterCategories={filterCategories}
        applications={applications}
        handleRedeploy={handleRedeploy}
        loading={applicationsStatus === 'loading'}
      />
      <DeployApplicationModal modal={modal} />
    </div>
  )
}
